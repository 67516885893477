import { useRef, useEffect } from 'react'
import { Form, Modal } from 'antd'
import { FormInstance } from 'antd/es/form/Form'

import ValidationRulesForm from './ValidationRuleForm'

const useResetFormOnCloseModal = ({
  form,
  isOpen,
}: {
  form: FormInstance
  isOpen: boolean
}) => {
  const prevOpenRef = useRef<boolean>()
  useEffect(() => {
    prevOpenRef.current = isOpen
  }, [isOpen])
  const prevOpen = prevOpenRef.current

  useEffect(() => {
    if (!isOpen && prevOpen) {
      form.resetFields()
    }
  }, [form, prevOpen, isOpen])
}

type Props = {
  isOpen: boolean
  onCancel: () => void
  schemeIds?: number[]
  hardwareTypes?: number[]
}

const ValidationRuleModalForm = ({
  isOpen,
  onCancel,
  schemeIds,
  hardwareTypes,
}: Props) => {
  const [form] = Form.useForm()

  useResetFormOnCloseModal({ form, isOpen })

  const onOk = () => {
    form.submit()
  }

  return (
    <Modal
      title="Create a new validation rule"
      open={isOpen}
      width={800}
      onOk={onOk}
      onCancel={onCancel}
    >
      <ValidationRulesForm
        form={form}
        schemeIds={schemeIds}
        hardwareTypes={hardwareTypes}
      />
    </Modal>
  )
}

export default ValidationRuleModalForm
