import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import {
  Box,
  colors,
  Flex,
  Heading,
  Text,
  TextLink,
} from '@weareberyl/design-system'
import {
  Button,
  Card,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import HeadTitle from 'components/HeadTitle'
import {
  FirmwareStatus,
  OTAFirmwaresDocument,
  OTAFirmwaresQuery_ota_firmwares_OTAFirmware,
  useDeleteOTAFirmwareMutation,
  usePromoteOTAFirmwareMutation,
} from 'gql/generated/graphql'
import useOTAFirmwares from 'hooks/OTA/useOTAFirmwares'
import { sortBy } from 'lodash'
import { useState } from 'react'
import config from 'constants/config'
import CreateOTAFirmwareModal from './CreateOTAFirmwareModal'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useCurrentUser } from 'hooks'

const unselectableStyle = {
  webkitUserSelect: 'none',
  mozUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none',
}

const changeRequestNotice = config.REACT_APP_ENV === 'production' && (
  <>
    <br />
    <br />
    This change requires operational consent, and an approved Change Request in
    ServiceNow
    <br />
    <br />
    <TextLink
      style={{ color: colors.blueberry }}
      onClick={() =>
        window.open(
          'https://beryl.service-now.com/now/nav/ui/classic/params/target/wizard_view.do%3Fsys_target%3D%26sysparm_wizardAction%3Dsysverb_new%26sysparm_parent%3D8db4a378c611227401b96457a060e0f4%26%26sys_id%3D-1%26sys_target%3Dchange_request%26sysparm_fixed_query%3D%26sysparm_group_sort%3D%26sysparm_query%3D%26sysparm_referring_url%3Dchange_request_list.do',
        )
      }
    >
      Raise a change request
    </TextLink>
  </>
)

const RenderTag = ({ value }) => {
  const index = value.search(/[^0]/)
  const padding = value.substring(0, index)
  const content = value.substring(index)

  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      <Tooltip open={copied} title="Copied!">
        <Tag>
          <Flex style={{ flexWrap: 'nowrap' }}>
            <Text
              color={colors.afternoon}
              style={{ ...unselectableStyle, whiteSpace: 'nowrap' }}
            >
              {padding}
            </Text>
            <Text
              color={colors.grape}
              style={{ ...unselectableStyle, whiteSpace: 'nowrap' }}
            >
              {content}
            </Text>
          </Flex>
        </Tag>
      </Tooltip>
    </CopyToClipboard>
  )
}

const renderTag = value => <RenderTag value={value} />

const getColorFromStatus = status => {
  switch (status) {
    case FirmwareStatus.deployed:
      return colors.berylGreen
    case FirmwareStatus.in_deployment:
      return colors.tumeric
    case FirmwareStatus.not_deployed:
      return colors.brick
    default:
      return colors.haze
  }
}

const renderStatus = (status: FirmwareStatus) => {
  return <Tag color={getColorFromStatus(status)}>{status}</Tag>
}

const ChangeStatusButton = ({
  record,
}: {
  record: OTAFirmwaresQuery_ota_firmwares_OTAFirmware
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = useForm()
  const newStatus = Form.useWatch('status', form)
  const [promoteFirmware] = usePromoteOTAFirmwareMutation()

  const onFinish = ({ status }) => {
    const text =
      status === FirmwareStatus.deployed
        ? `Promoting this firmware will cause it to roll out automatically, make it installable 
        from the Engineering App and remove any existing deployed firmware for the corresponding target`
        : 'Withdrawing this firmware means it will no longer roll out automatically or be installable via the Engineering App'

    const content = (
      <>
        {text}
        {changeRequestNotice}
      </>
    )

    const close = () => setModalOpen(false)
    const onOk = async () => {
      close()
      try {
        await promoteFirmware({ variables: { id: record.id, status } })
        message.success('Firmware status changed successfully!')
      } catch (e) {
        message.error(`Error changing firmware status: ${e}`)
      }
    }

    Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content,
      onOk,
      okText: 'Confirm',
      style: { top: 125 },
      onCancel: close,
    })
  }

  const disabled = record.status === newStatus

  return (
    <>
      <Button
        type="text"
        shape="round"
        icon={<EditOutlined />}
        onClick={() => setModalOpen(true)}
      />
      <Modal
        title="Edit Firmware"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={() => form.submit()}
        okButtonProps={{ disabled }}
      >
        <Box pb={3}>
          <Descriptions column={2}>
            <Descriptions.Item label="Model">
              <Flex style={{ whiteSpace: 'nowrap' }}>
                {record.hardware_model}
              </Flex>
            </Descriptions.Item>
            <Descriptions.Item label="Component">
              <Flex style={{ whiteSpace: 'nowrap' }}>
                {record.firmware_type}
              </Flex>
            </Descriptions.Item>
            <Descriptions.Item label="Hardware Version">
              {renderTag(record.hardware_version)}
            </Descriptions.Item>
            <Descriptions.Item label="Firmware Version">
              {renderTag(record.firmware_version)}
            </Descriptions.Item>
          </Descriptions>
        </Box>
        <Form form={form} onFinish={onFinish}>
          <Form.Item label="Status" name="status" initialValue={record.status}>
            <Select>
              <Select.Option value={FirmwareStatus.deployed}>
                Deployed
              </Select.Option>
              <Select.Option value={FirmwareStatus.not_deployed}>
                Not Deployed
              </Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const DeleteButton = ({
  record,
}: {
  record: OTAFirmwaresQuery_ota_firmwares_OTAFirmware
}) => {
  const [deleteFirmwareMutation] = useDeleteOTAFirmwareMutation({
    variables: { id: record.id },
    refetchQueries: [OTAFirmwaresDocument],
  })

  const deleteFirmware = async () => {
    try {
      await deleteFirmwareMutation()
      message.success('Successfully deleted firmware!')
    } catch (e) {
      message.error(`Error deleting firmware: ${e}`)
    }
  }

  const confirmDeletion = () => {
    const { update } = Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleFilled />,
      content: null,
      onOk: deleteFirmware,
      okText: 'Confirm',
      okButtonProps: { disabled: true },
      style: { top: 200 },
    })

    const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
      if (e.target.value === record.file_name) {
        update({ okButtonProps: { disabled: false } })
      } else {
        update({ okButtonProps: { disabled: true } })
      }
    }

    update({
      content: (
        <>
          Deleting this firmware means it will not be installable via any means
          {changeRequestNotice}
          <br />
          <br />
          Please type
          <br />
          <Text fontFamily="Hellix-SemiBold">{record.file_name}</Text>
          <br />
          in the box below to confirm:
          <Input
            onChange={onChange}
            onPaste={e => {
              e.preventDefault()
              return false
            }}
          />
        </>
      ),
    })
  }

  return (
    <Button
      type="text"
      shape="round"
      icon={<DeleteOutlined />}
      onClick={confirmDeletion}
    />
  )
}

const renderActions = (
  filename: string,
  record: OTAFirmwaresQuery_ota_firmwares_OTAFirmware,
) => (
  <Flex style={{ gap: 1 }}>
    <ChangeStatusButton record={record} />
    <Button
      type="text"
      shape="round"
      icon={<DownloadOutlined />}
      onClick={() =>
        window.location.assign(
          `https://storage.googleapis.com/${config.GOOGLE_PROJECT_ID}-scooter-firmware/${filename}`,
        )
      }
    />
    <DeleteButton record={record} />
  </Flex>
)

const OTAFirmware = () => {
  const [user] = useCurrentUser()
  const canEdit = user?.is_super_admin || false
  const { data: firmwares } = useOTAFirmwares()
  const sortedFirmware = sortBy(
    (firmwares?.filter(f => Boolean(f)) ??
      []) as OTAFirmwaresQuery_ota_firmwares_OTAFirmware[],
    [
      f => f.hardware_model,
      f => f.firmware_type,
      f => f.hardware_version,
      f => f.status,
    ],
  ).reduce((agg, f) => {
    agg[f.hardware_model] = agg[f.hardware_model] ?? []
    agg[f.hardware_model].push(f)
    return agg
  }, {}) as Record<string, OTAFirmwaresQuery_ota_firmwares_OTAFirmware[]>

  const columns = [
    {
      title: 'Component',
      dataIndex: 'firmware_type',
      key: 'firmware_type',
    },
    {
      title: 'Hardware Version',
      dataIndex: 'hardware_version',
      key: 'hardware_version',
      render: renderTag,
    },
    {
      title: 'Firmware Version',
      dataIndex: 'firmware_version',
      key: 'firmware_version',
      render: renderTag,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
    },
    ...(canEdit
      ? [
          {
            title: 'Actions',
            dataIndex: 'file_name',
            key: 'actions',
            render: renderActions,
          },
        ]
      : []),
  ]

  return (
    <>
      <HeadTitle pageTitle="OTA Firmware" />
      <Card>
        <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
          <Heading variant="callout">OTA Firmware</Heading>
          {canEdit && <CreateOTAFirmwareModal />}
        </Flex>
        <Box>
          <Text variant="small">
            Use this page to manage firmware versions across Scooters and
            Segways.
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td>{renderStatus(FirmwareStatus.deployed)}</td>
                  <td>
                    this firmware will be installed automatically across the
                    fleet, and can be installed via the Engineering App
                  </td>
                </tr>
                <tr>
                  <td>{renderStatus(FirmwareStatus.not_deployed)}</td>
                  <td>this firmware can only be installed manually</td>
                </tr>
              </tbody>
            </table>
          </Text>
        </Box>
        {Object.entries(sortedFirmware).map(([model, firmwares]) => (
          <Box key={model} py={5}>
            <Box p={2}>
              <Heading>{model}</Heading>
            </Box>
            <Table
              dataSource={firmwares}
              columns={columns}
              rowKey={firmware => firmware.id}
              pagination={{ pageSize: 50, hideOnSinglePage: true }}
            />
          </Box>
        ))}
      </Card>
    </>
  )
}

export default OTAFirmware
