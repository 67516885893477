import { Box, Flex, Heading } from '@weareberyl/design-system'
import { Card } from 'antd'
import { LoadingComponent } from 'components/Query/utils'
import CardSection from 'components/shared/CardSection'
import useOTAInformation from 'hooks/OTA/useOTAInformation'
import OTASideload from './OTASideload'
import { useCurrentUser } from 'hooks'
import { Center } from 'components/Layout'
import { WarningFilled } from '@ant-design/icons'

interface IDeviceInformation {
  id: string
}

const OTADeviceInformation = ({ id }: IDeviceInformation) => {
  const { data, loading, error } = useOTAInformation(id)
  const [user] = useCurrentUser()

  const Content = () => {
    if (error)
      return (
        <Center className="text-center">
          <WarningFilled style={{ fontSize: '20px' }} />
          <h4>Could not load Device Configuration</h4>
        </Center>
      )

    if (loading || !data) return <LoadingComponent />

    return (
      <>
        <CardSection
          title="Server"
          items={[
            {
              key: 'Address',
              value: data?.server_address,
            },
            {
              key: 'Port',
              value: data?.server_port,
            },
          ]}
        />
        <Flex justifyContent="space-between" width="100%">
          <Box mr={5} flex={1}>
            <Flex>
              <CardSection
                title="IoT"
                items={[
                  {
                    key: 'Hardware',
                    value: data?.hardware_versions.iot,
                  },
                  {
                    key: 'Firmware',
                    value: data?.firmware_versions.iot,
                  },
                ]}
              />
            </Flex>
          </Box>
          <Box flex={1}>
            <Flex>
              <CardSection
                title="ECU"
                items={[
                  {
                    key: 'Hardware',
                    value: data?.hardware_versions.ecu,
                  },
                  {
                    key: 'Firmware',
                    value: data?.firmware_versions.ecu,
                  },
                ]}
              />
            </Flex>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" width="100%">
          <Box mr={5} flex={1}>
            <Flex>
              <CardSection
                title="Meter"
                items={[
                  {
                    key: 'Hardware',
                    value: data?.hardware_versions.meter,
                  },
                  {
                    key: 'Firmware',
                    value: data?.firmware_versions.meter,
                  },
                ]}
              />
            </Flex>
          </Box>
          <Box flex={1}>
            <Flex>
              {data?.hardware_versions.repeater && (
                <CardSection
                  title="Meter"
                  items={[
                    {
                      key: 'Hardware',
                      value: data?.hardware_versions.repeater,
                    },
                    {
                      key: 'Firmware',
                      value: data?.firmware_versions.repeater,
                    },
                  ]}
                />
              )}
            </Flex>
          </Box>
        </Flex>
      </>
    )
  }

  return (
    <Card
      title={
        <Flex justifyContent="space-between" alignItems="center" height={45}>
          <Heading variant="h2">Device Configuration</Heading>
          {user?.is_super_admin && <OTASideload id={id} />}
        </Flex>
      }
    >
      <Content />
    </Card>
  )
}

export default OTADeviceInformation
