import { gql, useMutation } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Menu, message, Modal } from 'antd'
import { Text, TextLink } from '@weareberyl/design-system'
import { auth } from 'utils/firebase'
import config from 'constants/config'
import {
  AssistConfig,
  LockConfiguration,
  ModuleHardware,
} from '__generated__/globalTypes'
import { PermissionOnly } from 'components/Session'
import { useMaintenanceStart, useMaintenanceEnd, useCurrentUser } from 'hooks'
import { getLogsLink } from 'utils'
import DatadogLogo from './DataDogLogo'
import ClearBladeLogo from './ClearBladeLogo'

type DEFAULT_MODULE_ACTION_VARIABLES = {
  moduleId: string
}

const confirm = Modal.confirm
const VR_BIKE_ENDPOINT = config.REACT_APP_VR_BIKE_ENDPOINT
const isProduction = config.REACT_APP_ENV === 'production'

const START_JOURNEY_BY_MODULE_ID = gql`
  mutation START_JOURNEY_BY_MODULE_ID__DASHBOARD($moduleId: ID!) {
    start_journey_by_module_id(module_id: $moduleId) {
      id
      is_complete
      state {
        message
        time
        id
      }
    }
  }
`
export const ViewDataDogLogs = ({
  moduleId,
  ...props
}: {
  moduleId: string
}) => {
  const logsLink = (moduleId: string) => {
    return getLogsLink(
      `(@module_id:${moduleId} OR @variables.moduleId:${moduleId})`,
    )
  }

  return (
    <Menu.Item
      icon={<DatadogLogo height={15} />}
      onClick={() => window.open(logsLink(moduleId), '_blank')}
      {...props}
    >
      <Text variant="grape" size="small" fontFamily="Hellix-SemiBold">
        View Logs
      </Text>
    </Menu.Item>
  )
}

export const ViewIoTCore = ({ moduleId, ...props }: { moduleId: string }) => {
  const iotLink = (moduleId: string) => {
    const proj = config.GOOGLE_PROJECT_ID
    return `https://iot.clearblade.com/iot-core/app/locations/europe-west1/registries/master/devices/details/${moduleId}/overview?project=${proj}`
  }

  return (
    <Menu.Item
      icon={<ClearBladeLogo height={15} />}
      onClick={() => window.open(iotLink(moduleId), '_blank')}
      {...props}
    >
      <Text variant="grape" size="small" fontFamily="Hellix-SemiBold">
        View IoT Core
      </Text>
    </Menu.Item>
  )
}

export const StartJourney = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Journey Started!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={START_JOURNEY_BY_MODULE_ID}
    >
      {startJourney => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => startJourney({ variables: { moduleId } })}
        >
          Start journey
        </TextLink>
      )}
    </Mutation>
  )
}

const RELEASE_LOCK = gql`
  mutation RELEASE_LOCK__DASHBOARD($moduleId: ID!) {
    release_lock(module_id: $moduleId)
  }
`
export const ReleaseLock = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Lock Released!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={RELEASE_LOCK}
    >
      {release => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => {
            confirm({
              zIndex: 1050,
              title: `Are you sure?`,
              content: `Please confirm that you would like to unlock bike ${moduleId}`,
              onOk: () => release({ variables: { moduleId } }),
              onCancel: undefined,
            })
          }}
        >
          Release lock
        </TextLink>
      )}
    </Mutation>
  )
}

const HIBERNATE = gql`
  mutation HIBERNATE__DASHBOARD($moduleId: ID!) {
    hibernate(module_id: $moduleId)
  }
`
export const Hibernate = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Hibernate mode set!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={HIBERNATE}
    >
      {hibernate => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => {
            confirm({
              zIndex: 1050,
              title: `Are you sure?`,
              content: `Please confirm that you would like to put this bike into hibernate mode ${moduleId}`,
              onOk: () => hibernate({ variables: { moduleId } }),
              onCancel: undefined,
            })
          }}
        >
          Hibernate
        </TextLink>
      )}
    </Mutation>
  )
}

const REBOOT = gql`
  mutation REBOOT__DASHBOARD($moduleId: ID!) {
    reboot(module_id: $moduleId)
  }
`
export const Reboot = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Module reboot request sent')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={REBOOT}
    >
      {reboot => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => {
            confirm({
              zIndex: 1050,
              title: `Are you sure?`,
              content: `Please confirm that you would like to reboot this bike ${moduleId}`,
              onOk: () => reboot({ variables: { moduleId } }),
              onCancel: undefined,
            })
          }}
        >
          Reboot
        </TextLink>
      )}
    </Mutation>
  )
}

const SEND_TELEMETRY = gql`
  mutation SEND_TELEMETRY__DASHBOARD($moduleId: ID!) {
    send_telemetry(module_id: $moduleId)
  }
`
export const SendTelemetry = ({
  moduleId,
}: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Telemetry request sent!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={SEND_TELEMETRY}
    >
      {sendTelemetry => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => sendTelemetry({ variables: { moduleId } })}
        >
          Request telemetry
        </TextLink>
      )}
    </Mutation>
  )
}

export const MaintenanceStart = ({ vehicleId }: { vehicleId: string }) => {
  const [maintenanceStart] = useMaintenanceStart({ vehicleId })

  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          await maintenanceStart()
          message.info(`Vehicle set as unavailable!`)
        } catch (error) {
          message.error(`Something went wrong: ${error.message}`)
        }
      }}
    >
      Set unavailable
    </TextLink>
  )
}

export const MaintenanceEnd = ({ vehicleId }: { vehicleId: string }) => {
  const [maintenanceEnd] = useMaintenanceEnd({ vehicleId })

  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          await maintenanceEnd()
          message.info(`Vehicle set as available!`)
        } catch (error) {
          message.error(`Something went wrong: ${error.message}`)
        }
      }}
    >
      Set available
    </TextLink>
  )
}

export const AttachDrive = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          await fetch(`${VR_BIKE_ENDPOINT}/attach_drive/${moduleId}`, {
            method: 'PUT',
            headers: { Authorization: `Bearer ${token}` },
          })
          message.info('Drive attached')
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
          message.error('Something went wrong')
        }
      }}
    >
      Attach Drive
    </TextLink>
  )
}

export const RemoveDrive = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          await fetch(`${VR_BIKE_ENDPOINT}/remove_drive/${moduleId}`, {
            method: 'PUT',
            headers: { Authorization: `Bearer ${token}` },
          })
          message.info('Drive removed')
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
          message.error('Something went wrong')
        }
      }}
    >
      Remove Drive
    </TextLink>
  )
}

const LOCK = gql`
  mutation LOCK__DASHBOARD($moduleId: ID!) {
    lock(module_id: $moduleId)
  }
`
export const Lock = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Locked!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={LOCK}
    >
      {lock => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => {
            confirm({
              zIndex: 1050,
              title: `Are you sure?`,
              content: `Please confirm that you would like to lock ${moduleId}`,
              onOk: () => lock({ variables: { moduleId } }),
              onCancel: undefined,
            })
          }}
        >
          Lock
        </TextLink>
      )}
    </Mutation>
  )
}

export const UnDock = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          await fetch(`${VR_BIKE_ENDPOINT}/undock/${moduleId}`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          message.info('Bike undocked')
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
          message.error('Something went wrong')
        }
      }}
    >
      UnDock
    </TextLink>
  )
}

export const Dock = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          await fetch(`${VR_BIKE_ENDPOINT}/dock/${moduleId}`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          message.info('Bike docked')
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
          message.error('Something went wrong')
        }
      }}
    >
      Dock
    </TextLink>
  )
}

export const NormalPower = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={async () => {
        try {
          const token = await auth.currentUser?.getIdToken()
          await fetch(`${VR_BIKE_ENDPOINT}/power_mode/${moduleId}`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              power_mode: 0,
            }),
          })
          message.info('Normal power mode set')
        } catch (err) {
          // tslint:disable-next-line:no-console
          console.error(err)
          message.error('Something went wrong')
        }
      }}
    >
      Set normal power mode
    </TextLink>
  )
}

const RELEASE_BATTERY = gql`
  mutation RELEASE_BATTERY__DASHBOARD($moduleId: ID!) {
    release_battery(module_id: $moduleId)
  }
`
export const ReleaseBattery = ({
  moduleId,
}: DEFAULT_MODULE_ACTION_VARIABLES) => {
  return (
    <Mutation
      onCompleted={() => message.info('Battery released!')}
      onError={err => {
        message.error(`Something went wrong: ${err.message}`)
      }}
      mutation={RELEASE_BATTERY}
    >
      {release => (
        <TextLink
          variant="grape"
          size="small"
          onPress={() => {
            confirm({
              zIndex: 1050,
              title: `Are you sure?`,
              content: `Please confirm that you would like to release battery for ${moduleId}`,
              onOk: () => release({ variables: { moduleId } }),
              onCancel: undefined,
            })
          }}
        >
          Release battery
        </TextLink>
      )}
    </Mutation>
  )
}

const LOCATE_MODULE = gql`
  mutation LOCATE_MODULE($moduleId: ID!) {
    locate(module_id: $moduleId)
  }
`

export const LocateModule = ({ moduleId }: DEFAULT_MODULE_ACTION_VARIABLES) => {
  const [locateModule] = useMutation(LOCATE_MODULE, {
    variables: { moduleId },
    onCompleted: () => {
      message.info('Module locate triggered')
    },
    onError: () => message.error('Something went wrong'),
  })
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={() => {
        confirm({
          zIndex: 1050,
          title: `Are you sure?`,
          content: `Please confirm that you would like to trigger the module locate for ${moduleId}`,
          onOk: () => locateModule(),
          onCancel: undefined,
        })
      }}
    >
      Locate
    </TextLink>
  )
}

const SET_ASSIST = gql`
  mutation SET_ASSIST($moduleId: ID!, $assist_config: AssistConfig!) {
    set_assist_config(module_id: $moduleId, assist_config: $assist_config)
  }
`

export const SetAssistNormal = ({
  moduleId,
}: DEFAULT_MODULE_ACTION_VARIABLES) => {
  const assist_config = AssistConfig.ASSIST_CONFIG_NORMAL
  const [assistNormal] = useMutation(SET_ASSIST, {
    variables: { moduleId, assist_config },
    onCompleted: () => {
      message.info('Assist config set to normal')
    },
    onError: () => message.error('Something went wrong'),
  })
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={() => {
        confirm({
          zIndex: 1050,
          title: `Are you sure?`,
          content: (
            <>
              <p>
                Please confirm that you would like to set the assist config to
                normal for {moduleId}. Please note setting the assist config to
                normal means the assist follows this pattern:
              </p>
              <ul>
                <li>Lock closed: Assist off</li>
                <li>Lock open: Assist on</li>
                <li>Lock disconnected: Assist off</li>
              </ul>
            </>
          ),
          onOk: () => assistNormal(),
          onCancel: undefined,
        })
      }}
    >
      Set assist normal
    </TextLink>
  )
}

export const SetAssistDisabled = ({
  moduleId,
}: DEFAULT_MODULE_ACTION_VARIABLES) => {
  const assist_config = AssistConfig.ASSIST_CONFIG_DISABLE
  const [assistDisabled] = useMutation(SET_ASSIST, {
    variables: { moduleId, assist_config },
    onCompleted: () => {
      message.info('Assist config set to disabled')
    },
    onError: () => message.error('Something went wrong'),
  })
  return (
    <TextLink
      variant="grape"
      size="small"
      onPress={() => {
        confirm({
          zIndex: 1050,
          title: `Are you sure?`,
          content: (
            <>
              <p>
                Please confirm that you would like to set the assist config to
                disabled for {moduleId}. Please note setting the assist config
                to disabled means the assist follows this pattern:
              </p>
              <ul>
                <li>Lock closed: Assist off</li>
                <li>Lock open: Assist off</li>
                <li>Lock disconnected: Assist off</li>
              </ul>
            </>
          ),
          onOk: () => assistDisabled(),
          onCancel: undefined,
        })
      }}
    >
      Set assist disabled
    </TextLink>
  )
}

type VehicleContextMenuProps = {
  module_id: string
  hardware_type: ModuleHardware
  vehicleId: string
  lock_configuration?: LockConfiguration
}

export const VehicleContextMenu = ({
  module_id,
  hardware_type,
  vehicleId,
  lock_configuration,
}: VehicleContextMenuProps) => {
  const [user] = useCurrentUser()
  const has_electronic_lock =
    lock_configuration &&
    [
      LockConfiguration.electronic_lock,
      LockConfiguration.front_and_electronic_rear_lock,
    ].includes(lock_configuration)

  return (
    <Menu id="module-context-menu">
      {user?.is_super_admin && (
        <>
          <ViewDataDogLogs moduleId={module_id} />
          {hardware_type !== ModuleHardware.scooter && (
            <ViewIoTCore moduleId={module_id} />
          )}
          <Menu.Divider />
        </>
      )}
      {vehicleId && (
        <Menu.Item>
          <MaintenanceStart vehicleId={vehicleId} />
        </Menu.Item>
      )}
      {vehicleId && (
        <Menu.Item>
          <MaintenanceEnd vehicleId={vehicleId} />
        </Menu.Item>
      )}
      <Menu.Item>
        <ReleaseLock moduleId={module_id} />
      </Menu.Item>
      <Menu.Item>
        <Hibernate moduleId={module_id} />
      </Menu.Item>
      <Menu.Item>
        <Reboot moduleId={module_id} />
      </Menu.Item>
      <Menu.Item>
        <SendTelemetry moduleId={module_id} />
      </Menu.Item>
      <Menu.Item>
        <LocateModule moduleId={module_id} />
      </Menu.Item>
      {hardware_type === ModuleHardware.bbe && (
        <Menu.Item>
          <SetAssistNormal moduleId={module_id} />
        </Menu.Item>
      )}
      {hardware_type === ModuleHardware.bbe && (
        <Menu.Item>
          <SetAssistDisabled moduleId={module_id} />
        </Menu.Item>
      )}
      {!isProduction && hardware_type === ModuleHardware.scooter && (
        <Menu.Item key="releasebattery">
          <ReleaseBattery moduleId={module_id} />
        </Menu.Item>
      )}
      <Menu.Item>
        <PermissionOnly permission="journey.start">
          <StartJourney moduleId={module_id} />
        </PermissionOnly>
      </Menu.Item>
      {(VR_BIKE_ENDPOINT || !isProduction || has_electronic_lock) && (
        <Menu.Item key="lock">
          <Lock moduleId={module_id} />
        </Menu.Item>
      )}
      {VR_BIKE_ENDPOINT && [
        <Menu.Item key="undock">
          <UnDock moduleId={module_id} />
        </Menu.Item>,
        <Menu.Item key="dock">
          <Dock moduleId={module_id} />
        </Menu.Item>,
        <Menu.Item key="normalpower">
          <NormalPower moduleId={module_id} />
        </Menu.Item>,
        <Menu.Item key="attachdrive">
          <AttachDrive moduleId={module_id} />
        </Menu.Item>,
        <Menu.Item key="removedrive">
          <RemoveDrive moduleId={module_id} />
        </Menu.Item>,
      ]}
    </Menu>
  )
}
