import { Component, FormEvent } from 'react'
import { validate as uuidValidate } from 'uuid'
import { ApolloQueryResult } from '@apollo/client'
import { Form, Icon as LegacyIcon } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { CloseOutlined, SearchOutlined, WifiOutlined } from '@ant-design/icons'
import { Input, Dropdown, Select, Tooltip, Switch } from 'antd'
import { Flex, Card, Heading, Box } from '@weareberyl/design-system'

import Query from 'components/Query'
import { withScheme } from 'components/Scheme'
import { Actions } from 'components/Module/actions'
import {
  AVAILABILITY_ICONS_MAP,
  colorByAvailability,
  colorByOnlineStatus,
  colorByFixInvalid,
  iconByFixInvalid,
  colorByModuleState,
  capitalize,
  getIcon,
} from 'utils'
import { isPhone } from 'utils/mobileDetect'
import Table, { ICustomColumnProps } from 'components/Table'
import { QueryParams, withQueryParams } from 'utils/useQueryParams'
import ModuleLink from 'components/ModuleLink'
import { getModuleTitle } from 'components/ModuleHeader'
import HardwareTypeIcon from 'components/ModuleHeader/HardwareTypeIcon'
import StartDeployment from 'components/Deployment/Start'
import HeadTitle from 'components/HeadTitle'
import { MODULES_LIST_DETAILED_INFORMATION_FLAG } from 'constants/localStorage'

import { SCHEME } from 'types'
import { withFeatureFlags } from 'utils/withFeatureFlags'
import { FeatureFlags } from 'context/featureFlags'
import { LockState } from '__generated__/globalTypes'
import { getDerivedHardwareType } from 'utils/derived'

import {
  ModulesListDocument,
  ModulesListQuery,
  ZoneModulesListQuery_zone_Zone_table_ModuleConnection_nodes_Module as Module,
} from 'gql/generated/graphql'

const POSTGRES_INT_LIMIT = 2147483647

const SearchText = ({ text }: { text: string }) => (
  <div style={{ marginBottom: 12 }}>Search text: "{text}"</div>
)

const ModuleListTable = ({ tableProps, scheme }) => {
  const lockStateColumn: ICustomColumnProps<Module> = {
    title: 'Lock State',
    // TODO: need to move this to vehicle_lock_state
    dataIndex: ['lock_state', 'overall_state'],
    sorter: true,
    sortFunction: direction => ({
      lock_state: {
        overall_state: direction,
      },
    }),
    align: 'center',
    render: (m: LockState) => {
      if (!m) {
        return <></>
      }
      const stateText = capitalize(m)
      return (
        <Tooltip title={stateText ?? ''}>
          <LegacyIcon
            type={getIcon(m).icon}
            style={{ fontSize: 16, color: colorByModuleState(m) }}
          />
        </Tooltip>
      )
    },
  }
  const actionColumn: ICustomColumnProps<Module> = {
    title: '',
    className: 'xs-hide',
    align: 'center',
    render: ({ id, hardware_type, vehicle }) => {
      return (
        <Dropdown
          trigger={['click']}
          overlay={
            <Actions
              module_id={id}
              hardware_type={hardware_type}
              vehicleId={vehicle?.id}
              lock_configuration={vehicle?.detail?.lock_configuration}
            />
          }
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="ant-dropdown-link link">Actions</a>
        </Dropdown>
      )
    },
  }
  return (
    <Table
      id="modules-table"
      // @ts-ignore
      columns={[...columns, lockStateColumn, actionColumn]}
      scheme={scheme}
      {...tableProps}
    />
  )
}

export const columns: ICustomColumnProps<Module>[] = [
  {
    title: 'Module ID',
    dataIndex: 'id',
    sorter: true,
    sortFunction: (direction: string) => ({ id: direction }),
    render: (module_id: string, { vehicle }) => (
      <ModuleLink
        id={module_id}
        bikeScheme={vehicle?.detail?.scheme ?? undefined}
      />
    ),
  },
  {
    title: 'Frame',
    render: module => {
      const { id, hardware_type, vehicle } = module
      return (
        <Flex style={{ flexWrap: 'nowrap' }}>
          <ModuleLink id={id} bikeScheme={vehicle?.detail?.scheme}>
            {getModuleTitle(
              vehicle?.frame_id ?? null,
              getDerivedHardwareType(module),
            )}
          </ModuleLink>
          <HardwareTypeIcon hardwareType={hardware_type} mt="2px" ml={2} />
        </Flex>
      )
    },
  },
  {
    title: 'Unlock ID(s)',
    render: module => {
      let unlock_ids = `${module?.unlock_id}`

      if (module?.vehicle?.detail?.vehicle_unlock_id) {
        unlock_ids += ` / ${module.vehicle.detail.vehicle_unlock_id}`
      }

      return unlock_ids
    },
  },
  {
    title: 'Drive Battery',
    className: 'xs-hide',
    dataIndex: ['telemetry', 'drive', 'battery_percent'],
    render: battery => (battery ? `${battery}%` : '-'),
  },
  {
    title: 'Drive Range',
    className: 'xs-hide',
    dataIndex: ['telemetry', 'drive', 'estimated_range'],
    render: range => (range ? `${range} km` : '-'),
  },
  {
    title: 'Module Battery',
    className: 'xs-hide',
    dataIndex: ['telemetry', 'battery_percent'],
    sorter: true,
    key: 'battery_percent',
    sortFunction: direction => ({
      telemetry: {
        battery_percent: direction,
      },
    }),
    render: battery => (battery ? `${battery}%` : '-'),
  },
  {
    title: 'Power mode',
    className: 'xs-hide',
    dataIndex: ['iot_state', 'power_mode'],
  },
  {
    title: 'Firmware',
    className: 'xs-hide',
    dataIndex: ['iot_state', 'firmware_version'],
  },
  {
    title: 'Available',
    className: 'xs-hide',
    dataIndex: ['availability', 'status'],
    align: 'center',
    key: 'available',
    render: m => (
      <span>
        <LegacyIcon
          type={AVAILABILITY_ICONS_MAP[m]}
          style={{ fontSize: 22, color: colorByAvailability(m) }}
        />
      </span>
    ),
  },
  {
    title: 'GNSS fix',
    className: 'xs-hide',
    dataIndex: ['telemetry', 'fix_invalid'],
    align: 'center',
    key: 'fix_invalid',
    render: fix_invalid =>
      fix_invalid != null ? (
        <span>
          <LegacyIcon
            type={iconByFixInvalid(fix_invalid)}
            style={{
              fontSize: 22,
              color: colorByFixInvalid(fix_invalid),
            }}
          />
        </span>
      ) : (
        <span />
      ),
  },
  {
    title: 'Connectivity',
    dataIndex: ['connectivity', 'is_online'],
    align: 'center',
    render: isOnline =>
      isOnline != null ? (
        <span>
          <WifiOutlined
            style={{ fontSize: 22, color: colorByOnlineStatus(isOnline) }}
          />
        </span>
      ) : (
        <span />
      ),
  },
]

type Props = {
  scheme: SCHEME
  queryParams: typeof defaultQueryParams & QueryParams
  setQueryParams: (params: QueryParams) => void
  featureFlags: FeatureFlags
}

type State = {
  unlockIdValue: string
  selection: { id: string }[]
  version: string
  showDetailedInfo: boolean
}

const defaultState: State = {
  unlockIdValue: '',
  selection: [],
  version: '',
  showDetailedInfo: true,
}

const defaultQueryParams = {
  filterType: 'frame_id',
  searchValue: '',
}

const { Option } = Select

const FilterSelect = ({ onChange, defaultValue }) => (
  <Select
    id="module-filter-type-select"
    onChange={onChange}
    defaultValue={defaultValue}
    data-testid="filter-select"
  >
    <Option value="unlock_id" data-testid="filter-select-option">
      Unlock Id
    </Option>
    <Option value="module_id" data-testid="filter-select-option">
      Module Id
    </Option>
    <Option value="frame_id" data-testid="filter-select-option">
      Frame Id
    </Option>
    <Option value="vehicle_id" data-testid="filter-select-option">
      Vehicle Id
    </Option>
  </Select>
)

const filterMap = {
  unlock_id: searchValue => ({
    unlock_id: { like: `%${searchValue}%` },
  }),
  module_id: searchValue => ({
    id: { like: `%${searchValue}%` },
  }),
  frame_id: searchValue => ({
    // frame_id is an int so we can't apply the LIKE operator,
    // as that is a string operator. We need to do some typecasting on the backend.
    // until then you'll have to put in the exact frame_id.
    vehicle: { frame_id: { eq: searchValue } },
  }),
  vehicle_id: searchValue => ({
    vehicle: { id: { eq: searchValue } },
  }),
}

const createFilter = (filterType: string) => filterMap[filterType]

class ModuleList extends Component<Props, State> {
  state = defaultState

  componentDidMount() {
    this._rehydrateDetailedInfoSetting()
  }

  _onFilterChange = (filterType: string) => {
    if (this._isInputValid(filterType, this.state.unlockIdValue)) {
      this.props.setQueryParams({ filterType })
    } else {
      this.props.setQueryParams({ searchValue: '', filterType })
      this.setState({ unlockIdValue: '' })
    }
  }

  _onSubmit = (e: FormEvent) => {
    e.preventDefault()

    const { unlockIdValue } = this.state
    const { filterType } = this.props.queryParams
    if (this._isInputValid(filterType, unlockIdValue)) {
      this.props.setQueryParams({ searchValue: unlockIdValue, current: 1 })
    }
  }

  _onChangeSelection = (_selectedRowKeys, selectedRows) => {
    // tslint:disable
    this.setState({ selection: selectedRows })
  }

  _renderCloseIcon = () => {
    return <CloseOutlined onClick={() => this.setState(defaultState)} />
  }

  _isInputValid = (filterType: string, input: string): boolean => {
    if (input === '') {
      return true
    }

    if (filterType === 'frame_id') {
      if (isNaN(+input)) {
        return false
      } else {
        return parseInt(input) < POSTGRES_INT_LIMIT
      }
    }

    if (filterType === 'vehicle_id') {
      if (!uuidValidate(input)) {
        return false
      }
    }

    return true
  }

  _rehydrateDetailedInfoSetting = () => {
    const showDetailedInfo = window.localStorage.getItem(
      MODULES_LIST_DETAILED_INFORMATION_FLAG,
    )
    if (showDetailedInfo) {
      this.setState({
        showDetailedInfo: showDetailedInfo === 'true',
      })
    }
  }

  _toggleDetailedInfo = () => {
    this.setState(
      ({ showDetailedInfo }) => ({
        showDetailedInfo: !showDetailedInfo,
      }),
      () => {
        window.localStorage.setItem(
          MODULES_LIST_DETAILED_INFORMATION_FLAG,
          String(this.state.showDetailedInfo),
        )
      },
    )
  }

  _renderLayerToggle() {
    const { showDetailedInfo } = this.state

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '8px',
        }}
      >
        <p style={{ marginBottom: 0, marginRight: 20 }}>Detailed information</p>
        <Switch
          checked={showDetailedInfo}
          onChange={this._toggleDetailedInfo}
        />
      </div>
    )
  }

  static _getValidationMessage(filterType: string) {
    if (filterType === 'frame_id') {
      return 'Frame Id can only contain numbers, and cannot exceed 10 digits.'
    } else if (filterType === 'vehicle_id') {
      return 'Vehicle Id must be a valid uuid.'
    } else {
      return ''
    }
  }

  render() {
    const { scheme } = this.props
    const { unlockIdValue, showDetailedInfo, selection } = this.state

    const { searchValue, filterType } = this.props.queryParams

    // If a scheme is specified then query users for this scheme only.
    // If not, query all users as we're in Beryl Admin mode
    const scheme_id = scheme ? scheme.id : null
    const isValid = this._isInputValid(filterType, unlockIdValue)

    return (
      <>
        <HeadTitle pageTitle="Vehicles" />
        <Card p={5}>
          <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
            <Heading variant="callout">Vehicles</Heading>
            <Box>
              <StartDeployment module_ids={selection.map(d => d.id)} />
            </Box>
          </Flex>
          <Flex style={{ gap: '10px' }}>
            <Form onSubmit={this._onSubmit} style={{ flex: 1 }}>
              <Form.Item
                validateStatus={!isValid ? 'error' : undefined}
                help={
                  !isValid
                    ? ModuleList._getValidationMessage(filterType)
                    : undefined
                }
              >
                <Input
                  id="modules-search-input"
                  addonBefore={
                    <FilterSelect
                      onChange={this._onFilterChange}
                      defaultValue={filterType}
                    />
                  }
                  placeholder="Search"
                  onChange={e => {
                    this.setState({
                      unlockIdValue: e.currentTarget.value,
                    })
                  }}
                  defaultValue={searchValue}
                  prefix={<SearchOutlined />}
                  suffix={unlockIdValue && this._renderCloseIcon()}
                />
              </Form.Item>
              <input className="hide" type="submit" />
            </Form>

            {this._renderLayerToggle()}
          </Flex>
          {searchValue && <SearchText text={searchValue} />}
          <Query
            query={ModulesListDocument}
            variables={{
              scheme_id,
              where: searchValue ? [createFilter(filterType)(searchValue)] : [],
              is_mobile: isPhone() || !showDetailedInfo,
              lean_query: !showDetailedInfo,
              paginate: {
                page: this.props.queryParams.current,
                per_page: this.props.queryParams.pageSize,
              },
            }}
            waitFor="data.table.nodes"
            opts={{ showRefetchLoader: true }}
          >
            {(props: ApolloQueryResult<ModulesListQuery>) => (
              <ModuleListTable
                tableProps={{
                  ...props,
                  onChange: ({ current, pageSize }) => {
                    this.props.setQueryParams({ current, pageSize })
                  },
                  rowSelection: {
                    onChange: this._onChangeSelection,
                  },
                }}
                scheme={scheme}
              />
            )}
          </Query>
        </Card>
      </>
    )
  }
}

export default withFeatureFlags(
  withScheme(withQueryParams('module-table', ModuleList, defaultQueryParams)),
)
