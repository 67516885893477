import { useState } from 'react'
import { Form, message } from 'antd'
import { isMoment, Moment } from 'moment'

import { useValidationRules } from '../data'
import PromotionForm, { FormValues } from './PromotionForm'
import ValidationRuleModalForm from './ValidationRuleModalForm'
import type { FormValues as ValidationRulesFormValues } from './ValidationRuleForm'
import {
  type ValidationCriterionInput,
  type CreateEoJPromotionMutationVariables,
  type CreateValidationRulesMutationVariables,
  ProductDiscountType,
} from 'gql/generated/graphql'
import { isArray, startCase } from 'lodash'

const buildPromoInput = (
  values: FormValues,
): CreateEoJPromotionMutationVariables => {
  const [start_date, expiration_date] = values.promotion_dates ?? [null, null]

  return {
    promotion: {
      name: values.name,
      banner: values.banner,
      description: values.description,
      scheme_ids: values.scheme_ids,
      hardware_types: values.hardware_types,
      validation_rule_ids: values.validation_rule_ids,
      journey_first_free_minutes: values.journey_first_free_minutes,
      journey_percent_off_time_based_charges:
        values.journey_percent_off_time_based_charges,
      unlock_fee_percent_off: values.unlock_fee_percent_off,
      start_date: start_date
        ? start_date.startOf('day').toISOString()
        : undefined,
      expiration_date: expiration_date
        ? expiration_date.endOf('day').toISOString()
        : undefined,
      // TODO: is this actualy used? I think it's used for the price calculation
      // But what do we do if it's unlock and journey_minutes?
      discount: {
        percent_off: values.unlock_fee_percent_off,
        type: ProductDiscountType.percent,
      },
    },
  }
}

/**
 * Return value as string[] to normalise inputs for the mutation
 */
const valueAsString = (
  value: number | string | string[] | number[] | Moment,
): string[] => {
  if (isArray(value)) {
    return value.map((item: string | number) => item.toString())
  }
  if (isMoment(value)) {
    return [value.toISOString()]
  }
  return [value.toString()]
}

/**
 * Convert form inputs into variables for the mutation
 */
export const buildValidationRulesInput = ({
  logic,
  name,
  rules_list_customer = [],
  rules_list_redemption = [],
}: ValidationRulesFormValues): CreateValidationRulesMutationVariables => {
  const redepmtionRules: ValidationCriterionInput[] = rules_list_redemption.map(
    ({ property, operator, value }) => ({
      name: 'redemption.metadata',
      property,
      conditions: { [operator]: valueAsString(value) },
    }),
  )

  const customerRules: ValidationCriterionInput[] = rules_list_customer.map(
    ({ property, operator, value }) => ({
      name: 'customer.metadata',
      property,
      conditions: { [operator]: valueAsString(value) },
    }),
  )
  return {
    validationRules: {
      logic,
      name,
      rules_list: [...redepmtionRules, ...customerRules],
    },
  }
}

type Props = {
  onFinish: (values: CreateEoJPromotionMutationVariables) => Promise<void>
  saving: boolean
  values?: FormValues
}

const CombinedPromotionForm = ({ onFinish, saving, values }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  // Store scheme_ids and hardware_types values from campaign metadata
  // to pre-populate validation rules form if necesssary
  const [schemeIds, setSchemeIds] = useState<number[]>([])
  const [hardwareTypes, setHardwareTypes] = useState<number[]>([])
  const {
    rules,
    loading: loadingRules,
    createValidationRules,
  } = useValidationRules()

  const showRuleModal = () => {
    setIsOpen(true)
  }

  const hideRuleModal = () => {
    setIsOpen(false)
  }

  const onFinishPromotionForm = (values: FormValues) => {
    const variables = buildPromoInput(values)
    onFinish(variables)
  }

  const onFinishValidationRulesForm = async (
    values: ValidationRulesFormValues,
  ) => {
    // Convert form data and submit it to the mutation
    // returning the result to add the ID to the parent form field
    const variables = buildValidationRulesInput(values)

    try {
      const { data, errors } = await createValidationRules({ variables })
      if (data) {
        return data?.create_validation_rule.id ?? null
      }

      // GQL errors
      if (errors && errors?.length > 0) {
        message.error(errors[0].message)
      }
    } catch (e) {
      // Network errors and wotnot. including permission denied
      message.error(startCase(e.message))
    }

    return null
  }

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'validationRulesForm') {
          const { promotionForm } = forms

          // Save the validationRules form to the API and get back the ID
          // to add to the parent form
          const newRuleId = await onFinishValidationRulesForm(
            values as ValidationRulesFormValues,
          )

          if (!newRuleId) {
            return
          }

          // One validation rule but it's an array.
          // Add the ID that was just created
          promotionForm.setFieldsValue({
            validation_rule_ids: [newRuleId],
          })

          setIsOpen(false)
        } else if (name === 'promotionForm') {
          // Submit the main form
          onFinishPromotionForm(values as FormValues)
        }
      }}
      onFormChange={(name, { forms }) => {
        // Save these values from the promtion form, to pre-populate
        // validation rules with the same items
        if (name === 'promotionForm') {
          const { promotionForm } = forms
          setSchemeIds(promotionForm.getFieldValue('scheme_ids'))
          setHardwareTypes(promotionForm.getFieldValue('hardware_types'))
        }
      }}
    >
      <PromotionForm
        showRuleModal={showRuleModal}
        validationRuleOptions={rules}
        loadingRules={loadingRules}
        saving={saving}
        values={values}
      />
      <ValidationRuleModalForm
        isOpen={isOpen}
        onCancel={hideRuleModal}
        schemeIds={schemeIds}
        hardwareTypes={hardwareTypes}
      />
    </Form.Provider>
  )
}

export default CombinedPromotionForm
