import { Box, Flex } from '@weareberyl/design-system'
import { LoadingComponent } from 'components/Query/utils'
import CardSection from 'components/shared/CardSection'
import useOTAInformation from 'hooks/OTA/useOTAInformation'
import OTASideload from './OTASideload'
import { Center } from 'components/Layout'
import { WarningFilled } from '@ant-design/icons'
import { SuperAdminOnly } from 'components/Session'

interface IDeviceInformation {
  id: string
}

const OTADeviceInformation = ({ id }: IDeviceInformation) => {
  const { data, loading, error } = useOTAInformation(id)

  if (error)
    return (
      <Center className="text-center">
        <WarningFilled style={{ fontSize: '20px' }} />
        <h4>Could not load Device Configuration</h4>
      </Center>
    )

  if (loading || !data) return <LoadingComponent />

  return (
    <>
      <CardSection
        title="Server"
        items={[
          {
            key: 'Address',
            value: data?.server_address,
          },
          {
            key: 'Port',
            value: data?.server_port,
          },
        ]}
        titleStyle={{ mt: 0 }}
      />
      <Flex justifyContent="space-between" width="100%">
        <Box mr={5} flex={1}>
          <Flex>
            <CardSection
              title="IoT"
              items={[
                {
                  key: 'Hardware',
                  value: data?.hardware_versions.iot,
                },
                {
                  key: 'Firmware',
                  value: data?.firmware_versions.iot,
                },
              ]}
            />
          </Flex>
        </Box>
        <Box flex={1}>
          <Flex>
            <CardSection
              title="ECU"
              items={[
                {
                  key: 'Hardware',
                  value: data?.hardware_versions.ecu,
                },
                {
                  key: 'Firmware',
                  value: data?.firmware_versions.ecu,
                },
              ]}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex justifyContent="space-between" width="100%">
        <Box mr={5} flex={1}>
          <Flex>
            <CardSection
              title="Meter"
              items={[
                {
                  key: 'Hardware',
                  value: data?.hardware_versions.meter,
                },
                {
                  key: 'Firmware',
                  value: data?.firmware_versions.meter,
                },
              ]}
            />
          </Flex>
        </Box>
        <Box flex={1}>
          <Flex>
            {data?.hardware_versions.repeater && (
              <CardSection
                title="Meter"
                items={[
                  {
                    key: 'Hardware',
                    value: data?.hardware_versions.repeater,
                  },
                  {
                    key: 'Firmware',
                    value: data?.firmware_versions.repeater,
                  },
                ]}
              />
            )}
          </Flex>
        </Box>
      </Flex>
      <SuperAdminOnly>
        <Box mt={5}>
          <OTASideload id={id} />
        </Box>
      </SuperAdminOnly>
    </>
  )
}

export default OTADeviceInformation
