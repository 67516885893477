import { gql, useQuery } from '@apollo/client'
import {
  Flex,
  Box,
  Text,
  TextLink,
  getVehicleName,
  isElectricVehicle,
} from '@weareberyl/design-system'
import { formatDatetime, getPositionFromLocation } from 'utils'
import Copy from '../Copy'
import CardSection from 'components/shared/CardSection'

import {
  MODULE_TELEMETRY,
  MODULE_TELEMETRYVariables,
} from '../Module/__generated__/MODULE_TELEMETRY'
import { ModuleHardware } from '__generated__/globalTypes'
import Loading from 'components/Loading'
import { message } from 'antd'
import { getDerivedModule } from 'utils/derived'

const MAPS_LINK = ({ latitude, longitude }) =>
  `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`

const MODULE_TELEMETRY_QUERY = gql`
  query MODULE_TELEMETRY($id: ID!) {
    module(module_id: $id) {
      id
      hardware_type
      telemetry {
        id
        time
        fix_invalid
        latitude
        longitude
        altitude
        battery_percent
        battery_voltage
        max_error
        ambient_temperature
        dyno_pulses
        drive {
          battery_percent
          battery_health
          estimated_range
        }
      }
      vehicle {
        frame_type
      }
    }
  }
`

export default ({ id }: MODULE_TELEMETRYVariables) => {
  const { data, error, loading } = useQuery<MODULE_TELEMETRY>(
    MODULE_TELEMETRY_QUERY,
    { variables: { id }, pollInterval: 0 },
  )

  if (loading) {
    return <Loading />
  }

  if (error) {
    message.error(error.message)
    return null
  }

  const module = getDerivedModule(data?.module)
  const telemetry = module?.telemetry
  const hardwareType = module?.hardware_type
  if (telemetry === undefined || hardwareType === undefined) {
    return null
  }

  const fixInvalid = telemetry?.fix_invalid ? 'Invalid' : 'Valid'

  const positionLink = (
    <>
      <TextLink
        testID="module-telemetry-link"
        variant={telemetry ? 'default' : 'grape'}
        size="small"
        disabled={!telemetry}
        pr={1}
        onPress={() => {
          if (telemetry) {
            window.open(MAPS_LINK(telemetry), '_blank')
          }
        }}
      >
        {telemetry ? getPositionFromLocation(telemetry) : 'No position'}
      </TextLink>
      <Copy
        id="location"
        text={telemetry ? `${telemetry.latitude},${telemetry.longitude}` : ''}
        showText={false}
      />
    </>
  )

  return (
    <>
      <Text variant="small" mb={3}>
        Updated {formatDatetime(telemetry?.time)}
      </Text>
      <Flex justifyContent="space-between" width="100%">
        <Box flex="1" mr={5}>
          {isElectricVehicle(hardwareType) && (
            <Flex>
              <CardSection
                title={`${getVehicleName(hardwareType)} battery`}
                items={[
                  {
                    key: 'Range',
                    value: `${telemetry?.drive?.estimated_range ?? 0}km`,
                  },
                  {
                    key: 'Charge',
                    value: `${telemetry?.drive?.battery_percent ?? '-'}%`,
                  },
                  hardwareType === ModuleHardware.bbe && {
                    key: 'Health',
                    value: telemetry?.drive?.battery_health,
                  },
                ]}
              />
            </Flex>
          )}
          <Flex>
            <CardSection
              title="Module battery"
              items={[
                {
                  key: 'Charge',
                  value: `${telemetry?.battery_percent ?? '-'}%`,
                },
                {
                  key: 'Voltage',
                  value: `${telemetry?.battery_voltage?.toFixed(3) ?? '-'}V`,
                },
              ]}
            />
          </Flex>
          {!isElectricVehicle(hardwareType) && (
            <Flex>
              <CardSection
                title="Dyno"
                items={[{ key: 'Pulse', value: telemetry?.dyno_pulses }]}
              />
            </Flex>
          )}
        </Box>
        <Box flex="1">
          <Flex>
            <CardSection
              title="Sensors"
              items={[
                {
                  key: 'Ambient temperature',
                  value: `${
                    telemetry?.ambient_temperature?.toFixed(2) ?? '-'
                  }C`,
                },
              ]}
            />
          </Flex>
          <Flex>
            <CardSection
              title="Location"
              items={[
                { key: 'Current GNSS fix', value: fixInvalid },
                { key: 'Position', value: positionLink },
                {
                  key: 'Altitude',
                  value: `${telemetry?.altitude?.toFixed(2) ?? '-'}m`,
                },
                {
                  key: 'Max error',
                  value: `${telemetry?.max_error?.toFixed(2) ?? '-'}m`,
                },
              ]}
            />
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
